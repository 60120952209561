import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const IncintaLandingPage = () => (
  <PhysicianLandingPage
    physician="Dr. Macer"
    institution="Incinta"
    referralCode="INCINTA"
    physicianURL="https://incintafertility.com/"
  />
)

export default IncintaLandingPage
